import { Box, Typography } from "@mui/material";

const NotFound = () => {
  return (
    <Box>
      <Typography variant="h1" align="center" sx={{ color: "white" }}>
        404 - Page not found
      </Typography>
    </Box>
  );
};

export default NotFound;
